
<template>
    <div class="querycommunity">
        <LiefengContent>
            <template v-slot:title>今日发布</template>
            <template v-slot:toolsbarRight>
                <Form :label-colon="true" :inline="true" class="search">
                    <FormItem label="信息名称" :label-width="75">
                        <Input :maxlength="20" v-model.trim="selectObj.title" style="width: 150px; margin-left: 10px" @on-search="search"></Input>
                    </FormItem>
                    <FormItem label="拟制人" :label-width="75">
                        <Input :maxlength="20" v-model.trim="selectObj.createName" style="width: 150px; margin-left: 10px" @on-search="search"></Input>
                    </FormItem>
                    <FormItem label="发布范围" :label-width="75">
                        <CascaderCity @changeCasader="changeCasader" :resetNum="resetNum" :orgCode="4401" :width="250"></CascaderCity>
                    </FormItem>

                    <FormItem label="发布时间" :label-width="75">
                        <DatePicker @on-change="changeTime" :value="selectObj.gmtCreate" type="date" style="width: 200px" clearable :transfer="true"></DatePicker>
                    </FormItem>
                    <Button type="primary" icon="ios-search" @click="getList" style="margin-right: 10px">查询</Button>
                    <Button type="success" icon="ios-refresh" @click="rest" style="margin-right: 10px">重置</Button>
                </Form>
            </template>

            <template v-slot:contentArea>
                <LiefengTable
                    :talbeColumns="columns"
                    :tableData="data"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                ></LiefengTable>
            </template>
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import CascaderCity from "./components/CascaderCity"
import LiefengTable from "@/components/LiefengTable"
import { myMixin } from "./utils/utilsmixin.js"
export default {
    mixins: [myMixin],
    components: {
        LiefengContent,
        LiefengTable,
        CascaderCity,
    },
    created() {
        this.selectObj.gmtCreate = this.$core.formatDate(new Date(new Date().getTime()), "yyyy-MM-dd")

        this.getList()
    },
    data() {
        return {
            selectObj: {
                title: "",
                createName: "",
                gmtCreate: "",
                dataScope: "",
            },
            loading: false,
            columns: [
                {
                    align: "center",
                    minWidth: 120,
                    title: "所属栏目",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "a",
                                {
                                    on: {
                                        click: () => {
                                            this.goUrl(params.row)
                                        },
                                    },
                                },
                                params.row.columnName
                            ),
                        ])
                    },
                },
                { align: "center", minWidth: 120, title: "直播标题", key: "title" },
                { align: "center", minWidth: 120, title: "编码", key: "code" },
                { align: "center", minWidth: 160, title: "发布范围", key: "dataScopeName" },
                {
                    align: "center",
                    title: "发布时间",
                    key: "taskCount",
                    minWidth: 200,
                    render: (h, params) => {
                        return h("div", [h("span", {}, params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")])
                    },
                },
                { align: "center", minWidth: 120, title: "拟制人", key: "createName" },
                {
                    title: "当前状态",
                    key: "birthday",
                    minWidth: 120,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.status)
                    },
                },
                {
                    title: "操作",
                    align: "center",
                    width: 100,
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "error",
                                        size: "small",
                                    },
                                    style: {
                                        // display: this.buttonRoot == "1001" ? "block" : "none",
                                    },
                                    on: {
                                        click: () => {
                                            this.delete(params.row)
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ])
                    },
                },
            ],
            data: [],
            page: 1,
            pageSize: 20,
            total: 0,
        }
    },
    methods: {
        changeCasader(val) {
            this.selectObj.dataScope = val[val.length - 1]
        },
        changeTime(val) {
            this.selectObj.gmtCreate = val
        },
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        rest() {
            this.selectObj = {}
            // this.selectObj.gmtCreate = ''

            this.page = 1
            this.pageSize = 20
            this.getList()
        },
        goUrl(data){
             let content = ""
            if (data && data.functionType) {
                if (data.functionType == "1") {
                    // 图文
                    content = `/graphiccategory`
                } else if (data.functionType == "2") {
                    // 基本信息
                    content = `/houseingarrairsindex`
                } else if (data.functionType == "3") {
                    // 音视频
                    content = `/newhousingaffairs`
                } else if (data.functionType == "5") {
                    // 地图
                    content = `/mapclass`
                } else if (data.functionType == "16") {
                    content = `/voteindex`
                } else if (data.functionType == "17") {
                    // 答题活动
                    content = `/answerindex`
                } else if (data.functionType == "24") {
                    // 预约活动
                    content = `/activityindex`
                } else if (data.functionType == "25") {
                    // 问卷活动
                    content = `/questionnaireindex`
                }else{
                    content = data.url
                }
                content = content + `?menuId=${data.menuCode}`

                this.$router.push(content)
                
            }
        },
        getList() {
            this.loading = true
            this.$get("/gateway/syinfopublish/api/pc/information/v2/selectPublishLogByPage", {
                ...this.selectObj,
                orgCode:this.$core.getUserInfo().orgCode || parent.vue.loginInfo.userinfo.orgCode,
                page: this.page,
                pageSize: this.pageSize,
            })
                .then(res => {
                    this.loading = false
                    if (res.code == 200 && res.dataList) {
                        this.data = res.dataList
                        this.page = res.currentPage
                        this.total = res.maxCount
                    } else {
                        this.$Message.error({
                            content: "数据获取失败",
                            background: true,
                        })
                    }
                })
                .catch(err => {
                    this.loading = false
                    this.$Message.error({
                        content: "数据获取失败",
                        background: true,
                    })
                })
        },
        // 删除
        delete(data) {
            this.$Modal.confirm({
                title: "删除确定",
                content: "是否删除当前信息记录",
                onOk: () => {
                    this.$post("/gateway/syinfopublish/api/pc/information/v2/deletePublishLogById", {
                        id: data.id,
                    })
                        .then(res => {
                            if (res.code == 200) {
                                this.$Message.success({
                                    content: "删除成功",
                                    background: true,
                                })
                                this.getList()
                            } else {
                                this.$Message.error({
                                    content: "删除失败",
                                    background: true,
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.$Message.error({
                                content: "删除失败",
                                background: true,
                            })
                        })
                },
            })
        },
    },
}
</script>

<style scoped lang="less">
.querycommunity {
    /deep/ .table > .ivu-table > .ivu-table-tip > table > tbody > tr > td {
        height: calc(100vh - 150px);
        border-left: 1px solid #f8f8f9;
        border-bottom: 1px solid #f8f8f9;
        border-right: 1px solid #f8f8f9;
    }
}
</style>